import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from "./Home.module.scss";
import * as typographyStyles from "../../styles/typography.module.scss";
import Button from "../Button/Button";
import HomeIntroduction from "./HomeIntroduction/HomeIntroduction";
import ProductsGrid from "../products/ProductsGrid/ProductsGrid";
import useProducts from "../../hooks/useProducts";

const Home = () => {
  const [products, setProducts] = useState([]);
  const data = useStaticQuery(graphql`
    query {
      slide1: file(relativePath: { eq: "front2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide2: file(relativePath: { eq: "tube3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide3: file(relativePath: { eq: "tube4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      slide4: file(relativePath: { eq: "tube5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const [index, setIndex] = useState(0);

  const carouselImages = [
    data.slide1.childImageSharp.fluid,
    data.slide2.childImageSharp.fluid,
    data.slide3.childImageSharp.fluid,
    data.slide4.childImageSharp.fluid,
  ];

  useEffect(async () => {
    setProducts(await useProducts());
    const interval = setInterval(() => {
      setIndex((index) => index + 1);

      return () => {
        clearInterval(interval);
      };
    }, 3000);
  }, []);
  return (
    <>
      <BackgroundImage
        className={styles.frontImg}
        fluid={carouselImages[index % carouselImages.length]}
      >
        <span className={styles.backgroundOverlay} />
        <div className={styles.container}>
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>ZN AUDIO</h1>
            <h3 className={styles.tagLine}>
              Chuyên linh kiện lắp ráp và <br /> nâng cấp ampli đèn điện tử
            </h3>
          </div>
          <Button filled link="/cua-hang" text="Xem cửa hàng" />
        </div>
        <div
          style={{
            position: "absolute",
            color: "white",
            bottom: 10,
            right: 10,
            opacity: ".7",
          }}
        >
          <p>Ayaka Shop</p>
        </div>
      </BackgroundImage>

      <HomeIntroduction />
      <div className={styles.imagesWrapper}>
        <StaticImage
          placeholder="blurred"
          alt="Zn Audio Vinyl"
          className={styles.img}
          src="../../images/magnet.jpg"
        />
        <StaticImage
          placeholder="blurred"
          alt="Zn Audio Vinyl"
          className={styles.img}
          src="../../images/products.jpg"
        />
      </div>
      <div className={styles.productsWrapper}>
        <ProductsGrid
          withTitleMargin
          center
          title="Hàng mới về"
          products={products.slice(0, 8)}
          showReadMore
        />
        {/* <ProductsGrid
          withTitleMargin
          center
          showReadMore
          title="Ampli kit Nhật"
          products={products.slice(0, 8)}
        /> */}
      </div>

      <div className={styles.videoContainer}>
        <h2 className={`${typographyStyles.title} ${styles.headlineTitle}`}>
          Phòng nghe
        </h2>
        <iframe
          className={styles.video}
          width="100%"
          height="400"
          src="https://www.youtube.com/embed/SqFxeD8wuWs"
          title="Phòng nghe ZN Audio"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
};

export default Home;
