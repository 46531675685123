import * as React from "react";
import Layout from "../components/Layout/Layout";
import Home from "../components/Home/Home";

const IndexPage = () => {
  return (
    <Layout title="Home">
      <Home />
    </Layout>
  );
};

export default IndexPage;
