// extracted by mini-css-extract-plugin
export var backgroundOverlay = "Home-module--backgroundOverlay--hsA7p";
export var container = "Home-module--container--yWpkF";
export var frontImg = "Home-module--frontImg--wMtoF";
export var headlineTitle = "Home-module--headlineTitle--UetRF";
export var imagesWrapper = "Home-module--imagesWrapper--6ldcm";
export var img = "Home-module--img---0bC2";
export var productsWrapper = "Home-module--productsWrapper---iOvq";
export var tagLine = "Home-module--tagLine--aiuMr";
export var title = "Home-module--title--b1y5R";
export var titleWrapper = "Home-module--titleWrapper--21n6B";
export var video = "Home-module--video--GNgg6";
export var videoContainer = "Home-module--videoContainer--Jlmww";