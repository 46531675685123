import React from "react";
import * as typographyStyles from "../../../styles/typography.module.scss";
import * as styles from "./HomeIntroduction.module.scss";

const HomeIntroduction = () => {
  return (
    <div className={styles.container}>
      <p className={typographyStyles.title}>Lời ngỏ</p>
      <br />
      <p className={typographyStyles.body}>
        Thưa các anh em thân hữu gần xa, các bạn cùng sở thích về âm thanh – âm
        nhạc, cùng các bạn có chung niềm đam mê sự ngọt ngào và trong trẻo của
        Ampli Đèn điện tử!
      </p>
      <br />
      <p className={typographyStyles.body}>
        ZN Audio (Ayaka Shop) ra đời là kết quả của nhiều năm tháng say mê những
        âm thanh ma mị của đèn điện tử trong thiết bị audio, với mong muốn được
        kết nối, chia sẻ với các bạn, cùng tạo điều kiện cho niềm đam mê này lan
        tỏa, có thêm nhiều thân hữu có điều kiện tham gia cuộc chơi này, một
        cuộc chơi lành mạnh.
      </p>
      <br />
      <p className={typographyStyles.body}>
        Mong các bạn, quý khách hàng hãy ủng hộ để chúng tôi tìm tòi thêm nhiều
        sản phẩm góp phần cùng các bạn tạo cuộc chơi của chúng ta ngày một thăng
        hoa hơn…
        <br /> <br />
        Thân mến,
      </p>
    </div>
  );
};

export default HomeIntroduction;
